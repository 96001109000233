import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Header,
  Modal,
  Grid,
  Segment,
  Container,
  Dimmer,
  Loader,
  Label,
  Image,
} from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import SetPage from "../components/SetPage";
import OwnedCards from "../components/OwnedCards";
import axios from "axios";

const prodUrl = "https://poketrack-backend.charo.gg";
const devUrl = "http://127.0.0.1:5000";

const RetrieveAllUsers = () => {
  const { user } = useAuth0();
  const [setData, setSetData] = useState({});
  const { setname } = useParams();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const getSetData = async () => {
      try {
        if (user === undefined) {
          const metadataResponse = await axios.get(
            prodUrl + "/binder/set-name",
            {
              params: { name: setname, id: "" },
            }
          );

          const user_metadata = await metadataResponse.data;

          setSetData(user_metadata);
          setLoading(false);
        }
        {
          const metadataResponse = await axios.get(
            prodUrl + "/binder/set-name",
            {
              params: { name: setname, id: user.ProfileUserID },
            }
          );

          const user_metadata = await metadataResponse.data;

          setSetData(user_metadata);
          setLoading(false);
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    getSetData();
  }, [user?.sub]);
  if (isLoading) {
    const mewLoading =
      "https://media3.giphy.com/media/IQebREsGFRXmo/giphy.gif?cid=ecf05e47oj5t8kxu5xid65ntl3ht9fetsl7kiccxbyck3h3x&rid=giphy.gif&ct=s";

    return (
      <div>
        <div style={{ textAlign: "center" }}>
          Loading...
          <Image centered src={mewLoading}></Image>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Segment padded style={{ padding: "4.5em" }}>
        <Label color="blue" attached="top left" as="a" ribbon>
          {setData?.set_data?.name}
        </Label>
        <Image
          centered
          size="medium"
          src={setData?.pokemon[0]?.set?.images?.logo}
        />
        <Segment.Group
          style={{
            background: "rgba(255, 255, 255, 0.1)",
            borderRadius: "16px",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(20px)",
            WebkitBackdropFilter: "blur(20px)",
          }}
        >
          <Segment textAlign="center">Cards Owned: {setData?.owned}</Segment>
          <Segment textAlign="center">
            Total Cards: {setData?.set_data?.total}
          </Segment>
          <Segment textAlign="center">
            Release Date: {setData?.set_data?.releaseDate}
          </Segment>
        </Segment.Group>

        {<Segment textAlign="center">Cards Owned</Segment>}
        <OwnedCards data={setData || []} />
        <Segment textAlign="center">Cards Needed</Segment>
        <SetPage data={setData || []} />
      </Segment>
    </div>
  );
};

export default RetrieveAllUsers;
