import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LoginButton from "./LoginButton";
import { useAuth0 } from "@auth0/auth0-react";
import "./NewNavBar.css";
import { Link } from "react-router-dom";
import logo from "../images/PokeBinder.png";
import Audio from "./Audio";
import { Menu, Grid, Image, Segment } from "semantic-ui-react";

const Header = () => {
  const { user, isAuthenticated, loginWithRedirect, logout, isLoading } =
    useAuth0();

  // if (isAuthenticated) {
  return (
    <Navbar expand="false">
      <Audio></Audio>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        style={{ backgroundColor: "grey" }}
      />
      <Navbar.Collapse
        id="basic-navbar-nav"
        style={{
          backgroundColor: "rgb(50,50,50)",
          borderRadius: "5px",
          padding: "1rem",
          color: "white",
        }}
      >
        <Nav className="me-auto">
          <Nav.Link href="/" style={{ color: "white" }}>
            Pokemon Search
          </Nav.Link>
          <Nav.Link href="/sets" style={{ color: "white" }}>
            Browse by Set
          </Nav.Link>

          {isAuthenticated && (
            <Nav.Link href="/profile" style={{ color: "white" }}>
              Profile
            </Nav.Link>
          )}
          {isAuthenticated && (
            <Nav.Link href="/accountsettings" style={{ color: "white" }}>
              Account Settings
            </Nav.Link>
          )}
          <Nav.Link style={{ color: "white" }}>
            <LoginButton />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
  // }else {
  //   return (
  //     <>

  //     <Navbar bg="clear" expand="lg">
  //       <Container>
  //         <Navbar.Brand><Audio></Audio></Navbar.Brand>
  //         <Navbar.Toggle aria-controls="basic-navbar-nav" />
  //         <Navbar.Collapse id="basic-navbar-nav">
  //           <Nav className="me-auto">
  //             <Nav.Link href="/">Pokemon Search</Nav.Link>
  //             <Nav.Link href="/sets">Browse by Set</Nav.Link>
  //             <Nav.Link ><LoginButton /></Nav.Link>
  {
    /* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */
  }
  //       </Nav>
  //     </Navbar.Collapse>
  //   </Container>
  // </Navbar>
};

export default Header;
