import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Nav } from "react-bootstrap";
import { Image } from "semantic-ui-react";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const CardCarousel = ({ cardArray }) => {
  const pokemon = cardArray.map((pokemon) => {
    let pokemon_image = pokemon.images.small;
    let pokemon_name = pokemon.name;
    let pokemon_id = pokemon.id;
    let randomKey = new Date().getTime() * Math.random() * 100000;
    const linkSetName = `/card/${pokemon_id}`;

    const linkSetStringName = linkSetName.toString().replaceAll(" ", "");
    return (
      <div className="cards-container">
        {/* {pokemon_image} */}{" "}
        <Nav.Link href={linkSetStringName}>
          <Image src={pokemon_image} />
        </Nav.Link>
      </div>
    );
  });
  return (
    <>
      <Carousel
        draggable={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        autoPlay={true}
        keyBoardControl={true}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        itemClass="carousel-item-padding-40-px"
      >
        {pokemon}
      </Carousel>
    </>
  );
};

export default CardCarousel;
