import React from "react";
import { Progress, Grid } from "semantic-ui-react";

const TopCards = ({ topCardData }) => {
  return (
    <Grid container>
      <div className="images-leaning">
        <div>
          <img
            src={topCardData?.top_pokemon_3?.images?.small || ""}
            alt="3rd Pokemon"
          />
        </div>
        <div>
          <img
            src={topCardData?.top_pokemon_1?.images?.small || ""}
            alt="1st Pokemon"
          />
        </div>
        <div>
          <img
            src={topCardData?.top_pokemon_2?.images?.small || ""}
            alt="2nd Pokemon"
          />
        </div>
      </div>
    </Grid>
  );
};

export default TopCards;
