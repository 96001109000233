import React, { useEffect, useState } from "react";
import FeaturedSetsCard from "../components/FeaturedSetsCard";
import CardPageDetails from "../components/CardPageDetails";
import CardPageCarousel from "../components/CardPageCarousel";
import axios from "axios";
import { Nav } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./FeaturedSet.css";
import { useParams } from "react-router-dom";
import {
  Button,
  Header,
  Modal,
  Label,
  Grid,
  Image,
  List,
  Segment,
  Container,
} from "semantic-ui-react";
const prodUrl = "https://poketrack-backend.charo.gg";
const devUrl = "http://127.0.0.1:5000";
const apiUrl = "/binder/cardinfo";
const apiUrlSets = "/binder/featured";
const FeaturedSet = () => {
  const [data, setData] = useState({});
  const [dataFeatured, setDataFeatured] = useState({});
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      try {
        const getSetsResponse = await axios.get(prodUrl + apiUrl, {
          params: { name: "cel25c-88_A" },
        });

        const response = await getSetsResponse.data;

        setData(response.relatedPokemon);

        setLoading(false);
      } catch (e) {
        console.log(e.message);
      }
    };
    const getFeaturedSets = async () => {
      try {
        const getFeaturedSetsResponse = await axios.get(prodUrl + apiUrlSets);

        const response = await getFeaturedSetsResponse.data;

        setDataFeatured(response.pokemon);
      } catch (e) {
        console.log(e.message);
      }
    };
    getFeaturedSets();
    getData();
  }, []);
  if (isLoading) {
    const mewLoading =
      "https://media3.giphy.com/media/IQebREsGFRXmo/giphy.gif?cid=ecf05e47oj5t8kxu5xid65ntl3ht9fetsl7kiccxbyck3h3x&rid=giphy.gif&ct=s";

    return (
      <div>
        <div style={{ textAlign: "center" }}>
          Loading...
          <Image centered src={mewLoading}></Image>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          marginTop: "3rem",
        }}
      >
        <Card className="" bg="transparent" style={{ border: "none" }}>
          <Card.Header>
            <span className="featured-heading">Featured Mew Cards</span>
          </Card.Header>

          <CardPageCarousel cardArray={data} />
        </Card>
        <Card className="" bg="transparent" style={{ border: "none" }}>
          <Card.Header style={{ height: "100%" }}>
            <span className="featured-heading"> Newest Series</span>

            <Nav.Link href="/sets" style={{ border: "none" }}>
              <Button
                floated="right"
                style={{
                  backgroundColor: "rgb(50, 50, 50)",
                  border: "none",
                  textTransform: "uppercase",
                  color: "white",
                  transform: "translateY(-50%)",
                  fontFamily: "pokemon_gbregular",
                }}
              >
                View all...
              </Button>
            </Nav.Link>
          </Card.Header>

          <Container>
            <Grid stackable relaxed columns="equal">
              <Grid.Row>
                <FeaturedSetsCard cardData={dataFeatured} />
              </Grid.Row>{" "}
            </Grid>
          </Container>
        </Card>
        {/* <Label color="blue" attached="top left" ribbon> */}
        {/* <Image size="large" src={sNameLogo} />
          {sName} */}
        {/* </Label> */}
      </div>{" "}
    </>
  );
};

export default FeaturedSet;
