import React from "react";
import { Image } from "semantic-ui-react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import "./FeaturedSet.css";
import {
  Button,
  Header,
  Modal,
  Grid,
  Segment,
  List,
  Container,
  Label,
} from "semantic-ui-react";

const baseURL = "/sets/";
const SetCard = ({ cardData }) => {
  //   const seriesArray = cardData[seriesName];

  const seriesInfo = cardData.map((pokemonSeries) => {
    let setId = pokemonSeries.id;
    let logo = pokemonSeries.images.logo;
    let releaseDate = pokemonSeries.releaseDate;
    let series = pokemonSeries.series;
    let setName = pokemonSeries.name;
    let symbolImage = pokemonSeries.images.symbol;
    let linkSetName = baseURL + `${setId}`;
    let linkSetStringName = linkSetName.toString().replaceAll(" ", "");

    return (
      <Grid.Column style={{ padding: "2rem", margin: "1rem" }}>
        {/* <Nav.Link href={`/sets/${setId}`}>
          <Image centered style={{ maxWidth: "10rem" }} src={logo} />
        </Nav.Link> */}
        <a
          className="test"
          href={linkSetStringName}
          style={{
            margin: "10px",
            textAlign: "center",
            width: "150px",
            height: "100px",
            padding: "1rem",
          }}
          alt={setName}
        >
          <div
            className="set-card-tile"
            style={{
              width: "150px",
              height: "100px",
              backgroundImage: `url(${logo})`,
              backgroundSize: "contain",

              borderRadius: "16px",

              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              margin: "10px",
              position: "relative",
              // color: "white",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(20px)",
              WebkitBackdropFilter: "blur(20px)",
              textAlign: "center",
              border: "1px solid rgba(255, 255, 255, 0.3)",
            }}
          >
            <div
              style={{
                width: "100%",
                fontSize: "66%",
                position: "absolute",
                top: "0%",
                color: "white",
              }}
            >
              {releaseDate}
            </div>
            <div
              style={{
                width: "15%",
                fontSize: "66%",
                position: "absolute",
                right: "0%",
                color: "white",
              }}
            >
              <Image size="tiny" src={symbolImage} />
            </div>
            <div
              style={{
                width: "100%",
                fontSize: "66%",
                position: "absolute",
                bottom: "0%",
                color: "white",
              }}
            >
              {setName}
            </div>
          </div>{" "}
        </a>
      </Grid.Column>
    );
  });
  return <>{seriesInfo}</>;
};

export default SetCard;
