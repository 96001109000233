import React, { useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const prodUrl = "https://poketrack-backend.charo.gg";
const devUrl = "http://127.0.0.1:5000";

const baseURL = prodUrl + "/binder/";

const AddCard = ({ pokemonName, onCall, pokemonId }) => {
  const { user, isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  const [post, setPost] = React.useState({});

  let usernumID = user?.ProfileUserID;

  async function createPost() {
    let token = await getAccessTokenSilently();

    axios
      .post(
        baseURL,

        {
          name: pokemonName,
          pokemon_id: pokemonId,
          user_id: usernumID,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setPost(response.data);

        onCall(response.data);
      });
  }

  return (
    <>
      {!isAuthenticated && (
        <button
          className="button-modal-no-auth"
          onClick={() => loginWithRedirect()}
          //   style={{ padding: "15px" }}
        >
          Login/Sign Up
        </button>
      )}
      {isAuthenticated && (
        <button
          className="button-modal"
          onClick={createPost}
          //   style={{ padding: "15px" }}
        ></button>
      )}
    </>
  );
};

export default AddCard;
