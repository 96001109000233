import "./ImageList.css";
import {
  Button,
  Header,
  Image,
  Modal,
  Grid,
  Segment,
  Container,
} from "semantic-ui-react";
import ImageCard from "./ImageCard";
import { ToastContainer, toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import React from "react";
const PER_PAGE = 18;
const SetPage = (props) => {
  const [currentPage, setCurrentPage] = React.useState(0);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  function onClickHandler(post) {
    toast.success(
      post.pokemon_added + " has successfully been added to your Binder!",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }

  function onClickHandlerDelete(deleted) {
    toast.success(
      deleted.deleted + " has successfully been deleted from your Binder!",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  let owned_cards_array = props.data.owned_cards;
  if (props?.data?.owned_cards?.length > 0) {
    const images = props.data.pokemon
      .filter((o1) => {
        return !owned_cards_array.some(function (o2) {
          return o1.id === o2.id;
        });
      })

      .map((pokemon_name) => {
        let pokemon_number = pokemon_name?.number;
        let pokemon_set = pokemon_name?.set.name;
        let pokemon_id = pokemon_name?.id;
        let pokemon_image = pokemon_name?.images.large;
        let randomKey = new Date().getTime() * Math.random() * 100000;

        return (
          <ImageCard
            key={randomKey}
            pokemonid={pokemon_id}
            name={pokemon_name.name}
            image={pokemon_image}
            pokemon_number={pokemon_number}
            pokemon_set={pokemon_set}
            rare={pokemon_name.rarity}
            description={pokemon_name.flavorText}
            toastPop={onClickHandler}
            toastPopDelete={onClickHandlerDelete}
          />
        );
      });

    return (
      <>
        <div className="cards-container">
          {images}
          <ToastContainer />
        </div>
        {/* <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        // pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"paginationBttns"}
        nextLinkClassName={"paginationBttns"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      /> */}
      </>
    );
  } else {
    const images = props.data.pokemon.map((pokemon_name) => {
      let pokemon_number = pokemon_name?.number;
      let pokemon_set = pokemon_name?.set.name;
      let pokemon_id = pokemon_name?.id;
      let pokemon_image = pokemon_name?.images.large;
      let randomKey = new Date().getTime() * Math.random() * 100000;

      return (
        <ImageCard
          key={randomKey}
          pokemonid={pokemon_id}
          name={pokemon_name.name}
          image={pokemon_image}
          pokemon_number={pokemon_number}
          pokemon_set={pokemon_set}
          rare={pokemon_name.rarity}
          description={pokemon_name.flavorText}
        />
      );
    });

    return (
      <>
        <div className="cards-container">{images}</div>
        {/* <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        // pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"paginationBttns"}
        nextLinkClassName={"paginationBttns"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      /> */}
      </>
    );
  }
};

export default SetPage;
