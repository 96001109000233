import React, { Component } from "react";
import { Segment, Image, Accordion, Icon } from "semantic-ui-react";
import image_price from "../images/prices.png";
import carddetailsimage from "../images/CardDetails.png";
import TCGplayerLogo from "../images/TCGplayer-logo.png";
export default class AccordionExampleFluid extends Component {
  state = { activeIndex: 0 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    const pokemonName = this.props.cardData.name;
    const pokemonNumber = this.props.cardData.number;
    const pokemonRarity = this.props.cardData.rarity;
    const set = this.props.cardData.set.name;

    const releaseDate = this.props.cardData.set.releaseDate;
    const type = this.props.cardData.types[0];
    const nationalPokedexNumbers = this.props.cardData.nationalPokedexNumbers;
    let tcgplayerupdatedAt = "This card has no TCGplayer Pricing Data";
    let pricing = null;
    if (this.props.cardData.tcgplayer) {
      const tcgplayerURL = this.props.cardData.tcgplayer?.url;

      tcgplayerupdatedAt = this.props.cardData.tcgplayer?.updatedAt;

      let prices = Object.keys(this.props.cardData.tcgplayer?.prices);

      const validPrices = [];
      prices.forEach((price) => {
        if (this.props.cardData.tcgplayer?.prices[price]) {
          let cardVersion = price;

          validPrices.push(cardVersion);
        }

        //   console.log(prices);
      });
      pricing = validPrices.map((pricing) => {
        const arrayOfWords = pricing.split(/(?=[A-Z])/);

        function capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1);
        }
        for (let i = 0; i < arrayOfWords.length; i++) {
          arrayOfWords[i] = capitalizeFirstLetter(arrayOfWords[i]);
        }
        const formattedPriceName = arrayOfWords.join(" ");
        return (
          <Segment>
            <div class="ui tag labels">
              <a
                class="ui green image label"
                href={tcgplayerURL}
                target="_blank"
              >
                <img alt="" src={TCGplayerLogo} />
                Average {formattedPriceName} Market Price:
                <strong
                  style={{
                    fontSize: "15px",
                    fontFamily: "Lucida Console, Courier New, monospace",
                  }}
                >
                  $
                </strong>
                {this.props.cardData.tcgplayer.prices[pricing].market}
              </a>
            </div>
          </Segment>
        );
      });
    }

    return (
      <Accordion
        fluid
        style={{
          backgroundSize: "100% 100%",
          background: "rgba(50, 50, 50, 0.8)",
          borderRadius: "16px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(20px)",
          WebkitBackdropFilter: "blur(20px)",
          padding: "10px",
          border: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <Accordion.Title
        //   active={activeIndex === 0}
        //   index={0}
        //   onClick={this.handleClick}
        >
          {/* <Icon name="dropdown" /> */}
          <Image size="small" centered src={carddetailsimage}></Image>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <Segment.Group raised>
            <Segment>
              <strong style={{ fontStyle: "italic", fontWeight: "bold" }}>
                National Pokedex Number: {nationalPokedexNumbers}
              </strong>
            </Segment>
            <Segment>
              <strong style={{ fontStyle: "italic", fontWeight: "bold" }}>
                Name
              </strong>
              : {pokemonName}
            </Segment>
            <Segment>
              <strong style={{ fontStyle: "italic", fontWeight: "bold" }}>
                {" "}
                Card Number:{" "}
              </strong>
              {pokemonNumber}
            </Segment>{" "}
            <Segment>
              <strong style={{ fontStyle: "italic", fontWeight: "bold" }}>
                {" "}
                Rarity:{" "}
              </strong>
              {pokemonRarity}
            </Segment>
            <Segment>
              <strong style={{ fontStyle: "italic", fontWeight: "bold" }}>
                {" "}
                Set:
              </strong>{" "}
              {set}
            </Segment>
            <Segment>
              <strong style={{ fontStyle: "italic", fontWeight: "bold" }}>
                Release Date:
              </strong>{" "}
              {releaseDate}
            </Segment>
            <Segment>
              <strong style={{ fontStyle: "italic", fontWeight: "bold" }}>
                Type: {type}
              </strong>
            </Segment>
          </Segment.Group>
        </Accordion.Content>
        <Image size="tiny" centered src={image_price}></Image>
        <Segment.Group>
          {pricing}
          <Segment> Last Updated: {tcgplayerupdatedAt}</Segment>
        </Segment.Group>
      </Accordion>
    );
  }
}
