import React from "react";
import { Image, Form } from "semantic-ui-react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import SettingsForm from "../components/SettingsForm";
import "../components/ProfilePageTopCards.scss";
import myBackgroundImage from "../images/pokemonGBBG.jpeg";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import {
  Button,
  Header,
  Modal,
  Grid,
  Segment,
  Container,
} from "semantic-ui-react";
const prodUrl = "https://poketrack-backend.charo.gg";
const devUrl = "http://127.0.0.1:5000";
const baseURL = prodUrl + "/user/profileimage/";
const AccountSettings = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [userID, setUserID] = useState({});
  const [imageUrl, setImageUrl] = useState({});

  useEffect(() => {
    if (user?.ProfileUserID) {
      setUserID(user.ProfileUserID);

      setImageUrl(
        `https://storage-api.charo.gg/storage/?image_id=${user.ProfileUserID}&app_id=pokemonbinder`
      );
    }
  }, [user?.sub]);

  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files[0].size > 1000000) {
      toast.warn("Picture must me 1MB or less!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setImage(e.target.files[0]);
    }
  };

  async function handleSubmit() {
    const formData = new FormData();
    formData.append("filename", image);
    formData.append("destination", "images");
    formData.append("create_thumbnail", true);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    // let token = await getAccessTokenSilently();

    axios.post(baseURL + userID, formData, config).then((response) => {
      if (response.data.success === true) {
        toast.success("Profile picture has been updated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  }

  return (
    <Segment
      style={{
        backgroundImage: `url(${myBackgroundImage})`,

        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <ToastContainer />
      <Grid stackable columns={3} textAlign="center">
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>
            <Image src={imageUrl} size="medium" circular centered />
            <Form>
              {" "}
              <Form.Group>
                {" "}
                <Form.Input
                  width={12}
                  type="file"
                  onChange={handleImageChange}
                />
                <Form.Button
                  success
                  color="green"
                  content="Submit"
                  onClick={handleSubmit}
                />
              </Form.Group>
            </Form>
          </Grid.Row>
          <Grid.Row>
            <SettingsForm />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
      </Grid>
    </Segment>
  );
};

export default AccountSettings;
