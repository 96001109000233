import React, { Component, useState } from "react";

// Import your audio file
import song from "../static/finalpokebinder.mp3";
import logo from "../images/PokeBinder.png";
import { Menu, Grid, Image, Button, Segment, Popup } from "semantic-ui-react";
const App = () => {
  const [playing, setIsPlaying] = React.useState(false);
  const [audio, setAudio] = React.useState(new Audio(song));

  function playPause() {
    let isPlaying = playing;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!playing);
  }
  return (
    <>
      <div>
        {/* <Button style={{ background: "transparent" }} onClick={playPause}>
          {" "}
          <Popup
            trigger={<Image size="large" src={logo} />}
            content="Click for Pokemon Red Version Music while browsing..  "
            position="top middle"
          />
        </Button> */}
        <Image size="medium" src={logo} href="/"></Image>
      </div>
    </>
  );
};

export default App;
