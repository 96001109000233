import React, { useEffect, useState } from "react";

import "react-toastify/dist/ReactToastify.css";

import AddCardButton from "../components/utilites/api/api-components/AddCard";

import AddTopCardButton from "../components/utilites/api/api-components/AddTopcard1";
import DeleteCardButton from "../components/utilites/api/api-components/DeleteCard";

import CardPageDetails from "../components/CardPageDetails";
import CardPageCarousel from "../components/CardPageCarousel";
import axios from "axios";
import { Nav } from "react-bootstrap";
import carddetailsimage from "../images/CardDetails.png";
import SetList from "../components/SetList";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Button,
  Header,
  Modal,
  Label,
  Grid,
  Image,
  Card,
  Segment,
  Container,
} from "semantic-ui-react";
const prodUrl = "https://poketrack-backend.charo.gg";
const devUrl = "http://127.0.0.1:5000";
const apiUrl = "/binder/cardinfo";
const CardInfo = () => {
  const [data, setData] = useState({});

  const [otherCards, setOtherCards] = useState({});
  const [isLoading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        const getSetsResponse = await axios.get(prodUrl + apiUrl, {
          params: { name: id },
        });

        const response = await getSetsResponse.data;

        setData(response.pokemon);
        setOtherCards(response.relatedPokemon);
        setLoading(false);
      } catch (e) {
        console.log(e.message);
      }
    };

    getData();
  }, []);
  if (isLoading) {
    const mewLoading =
      "https://media3.giphy.com/media/IQebREsGFRXmo/giphy.gif?cid=ecf05e47oj5t8kxu5xid65ntl3ht9fetsl7kiccxbyck3h3x&rid=giphy.gif&ct=s";

    return (
      <div>
        <div style={{ textAlign: "center" }}>
          Loading...
          <Image centered src={mewLoading}></Image>
        </div>
      </div>
    );
  }

  function onClickHandler(post) {
    toast.success(
      post.pokemon_added + " has successfully been added to your Binder!",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }

  function onClickHandlerDelete(deleted) {
    toast.success(
      deleted.deleted + " has successfully been deleted from your Binder!",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }

  return (
    <div
      style={{
        backgroundSize: "100% 100%",
        background: "rgba(50, 50, 50, 0.8)",
        borderRadius: "16px",
        boxShadow: "0 4px 30px rgbxa(0, 0, 0, 0.1)",
        backdropFilter: "blur(20px)",
        WebkitBackdropFilter: "blur(20px)",
        border: "1px solid rgba(255, 255, 255, 0.3)",
      }}
    >
      <div class="ui stackable relaxed equal width internally celled grid">
        <div class="row">
          <div className="cards-container six wide column">
            <a href={data.images.large} target="_blank">
              <Image bordered size="large" src={data?.images?.large} />
            </a>
          </div>
          <div class="ten wide column">
            <Segment raised>
              <ToastContainer />{" "}
              <Grid
                container
                centered
                stackable
                columns="equal"
                padded="horizontally"
              >
                <Grid.Column>
                  <p>
                    Would you like to add <b>{data.name}</b> to your Binder?
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <DeleteCardButton
                    pokemonId={data.id}
                    pokemonName={data.name}
                    onCall={onClickHandlerDelete}
                  />
                </Grid.Column>
                <Grid.Column>
                  <AddCardButton
                    onCall={onClickHandler}
                    pokemonId={data.id}
                    pokemonName={data.name}
                  />
                </Grid.Column>
                <Grid.Column>
                  <AddTopCardButton
                    pokemonName={data.name}
                    pokemonid={data.id}
                  ></AddTopCardButton>
                </Grid.Column>
              </Grid>
            </Segment>
            <CardPageDetails cardData={data}></CardPageDetails>
          </div>
        </div>
        <div class="row ">
          <div class="ten wide column centered">
            <CardPageCarousel cardArray={otherCards} />
          </div>
          <div class=" three wide column">
            <a href={`/sets/${data.set.id}`}>
              <Image
                fluid
                style={{ marginTop: "3rem" }}
                src={data?.set.images?.logo}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardInfo;
