import "./ImageList.css";
import {
  Button,
  Header,
  Image,
  Modal,
  Grid,
  Segment,
  Container,
} from "semantic-ui-react";
import ImageCard from "./ImageCard";
import ReactPaginate from "react-paginate";
import React from "react";

const OwnedCards = (props) => {
  if (props?.data?.owned_cards?.length > 0) {
    const images = props?.data.owned_cards.map((pokemon_name) => {
      let pokemon_number = pokemon_name?.number;
      let pokemon_set = pokemon_name?.set.name;
      let pokemon_id = pokemon_name?.id;
      let pokemon_image = pokemon_name?.images.large;
      let randomKey = new Date().getTime() * Math.random() * 100000;

      return (
        <ImageCard
          key={randomKey}
          pokemonid={pokemon_id}
          name={pokemon_name.name}
          image={pokemon_image}
          pokemon_number={pokemon_number}
          pokemon_set={pokemon_set}
          rare={pokemon_name.rarity}
          description={pokemon_name.flavorText}
        />
      );
    });
    return (
      <>
        <div className="cards-container">{images}</div>
      </>
    );
  }
};

export default OwnedCards;
