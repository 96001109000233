import React, { useEffect, useState } from "react";

import axios from "axios";
import SetList from "../components/SetList";

import {
  Button,
  Header,
  Modal,
  Grid,
  Image,
  Segment,
  Container,
} from "semantic-ui-react";
const prodUrl = "https://poketrack-backend.charo.gg";
const devUrl = "http://127.0.0.1:5000";

const BrowseSets = () => {
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const baseURL = prodUrl + "/binder/set";
  useEffect(() => {
    const getSets = async () => {
      try {
        const getSetsResponse = await axios.get(baseURL);

        const response = await getSetsResponse.data;

        setData(response.pokemon);
        setLoading(false);
      } catch (e) {
        console.log(e.message);
      }
    };

    getSets();
  }, []);
  if (isLoading) {
    const mewLoading =
      "https://media3.giphy.com/media/IQebREsGFRXmo/giphy.gif?cid=ecf05e47oj5t8kxu5xid65ntl3ht9fetsl7kiccxbyck3h3x&rid=giphy.gif&ct=s";

    return (
      <div>
        <div style={{ textAlign: "center" }}>
          Loading...
          <Image centered src={mewLoading}></Image>
        </div>
      </div>
    );
  }
  return (
    <div style={{ marginTop: "20px" }}>
      {/* <button className="button-modal-del" onClick={getSets}></button> */}
      <Grid centered>
        <SetList cardSets={data} />
      </Grid>
    </div>
  );
};

export default BrowseSets;
