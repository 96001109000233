import React, { useEffect } from "react";
import { Button, Icon, Grid, Popup, Header, Image } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import "../../../ImageCard.scss";
const prodUrl = "https://poketrack-backend.charo.gg";
const devUrl = "http://127.0.0.1:5000";
const baseURL = prodUrl + "/user/";
const TopcardsButton = ({ pokemonName, pokemonid }) => {
  const { user, isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  const [post, setPost] = React.useState({});
  let usernumID = user?.ProfileUserID;

  async function updateTopCardOne() {
    let token = await getAccessTokenSilently();

    axios
      .patch(
        baseURL + usernumID + "/topcard",

        {
          top_pokemon_1: pokemonid,
          user_id: usernumID,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setPost(response.data);

        // onCall(response.data);
      });
  }

  async function updateTopCardTwo() {
    let token = await getAccessTokenSilently();

    axios
      .patch(
        baseURL + usernumID + "/topcard",

        {
          top_pokemon_2: pokemonid,
          user_id: usernumID,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setPost(response.data);

        // onCall(response.data);
      });
  }

  async function updateTopCardThree() {
    let token = await getAccessTokenSilently();

    axios
      .patch(
        baseURL + usernumID + "/topcard",

        {
          top_pokemon_3: pokemonid,
          user_id: usernumID,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setPost(response.data);

        // onCall(response.data);
      });
  }
  return (
    <>
      {isAuthenticated && (
        <Popup
          trigger={
            <Image
              circular
              centered
              size="tiny"
              src="https://media1.giphy.com/media/cVi6vx0zQKVzcptKyo/giphy.gif?cid=ecf05e47pk1d2pmi45ja98j932dabete0w1ra94eumgczaf5&rid=giphy.gif&ct=s"
            ></Image>
          }
          size="mini"
          hoverable
          position="top center"
        >
          <Header as="h4">Favorite Card</Header>
          <p>
            Make <b>{pokemonName}</b> your Favorite card
          </p>
          <Button onClick={updateTopCardOne}>I Choose you.</Button>

          <Header as="h4">2nd Favorite Card</Header>
          <p>
            Make <b>{pokemonName}</b> your 2nd Favorite card
          </p>
          <Button onClick={updateTopCardTwo}>I Choose you.</Button>

          <Header as="h4">3rd Favorite Card</Header>
          <p>
            Make <b>{pokemonName}</b> your Favorite card
          </p>
          <Button onClick={updateTopCardThree}>I Choose you.</Button>
        </Popup>
      )}
    </>
  );
};

export default TopcardsButton;
