import React, { useEffect, useState } from "react";
import ProfileBar from "../components/ProfileBar";
import { useParams } from "react-router-dom";
import ShareLinkButton from "../components/ShareLinkButton";
import ImageList from "../components/ImageList";
import { useAuth0 } from "@auth0/auth0-react";
import getUserData from "../components/utilites/api/api-components/RetrieveUserInfo";
import axios from "axios";
import ProfileBinder from "../components/ProfilePageCards";
import TopCards from "../components/ProfilePageTopCards";
import "../components/ProfilePageTopCards.scss";
import {
  Button,
  Header,
  Image,
  Modal,
  Dimmer,
  Loader,
  Popup,
  Grid,
  Segment,
  Container,
} from "semantic-ui-react";
import shareProfile from "../images/shareProfile.png";

const prodUrl = "https://poketrack-backend.charo.gg";
const devUrl = "http://127.0.0.1:5000";

const baseURL = prodUrl + "/user/";

const Profile = () => {
  const { user } = useAuth0();
  const [userMetadata, setUserMetadata] = useState({});
  const [isLoading, setLoading] = useState(true);

  const [imageUrl, setImageUrl] = useState({});
  const { id } = useParams();

  useEffect(() => {
    //check for q param
    // if q param in url check if access token matches q param is my prfoile = true
    //else user profile = false
    // if no q param default to user profile
    //

    const getUserMetadata = async () => {
      try {
        // const token = await getAccessTokenSilently();

        const metadataResponse = await axios.get(baseURL + id);

        const user_metadata = await metadataResponse.data;

        setUserMetadata(user_metadata);
        setLoading(false);
        if (user?.ProfileUserID) {
          setImageUrl(
            `https://storage-api.charo.gg/storage/?image_id=${id}&app_id=pokemonbinder`
          );
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [user?.sub]);
  if (isLoading) {
    return (
      <div>
        <Dimmer active>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      </div>
    );
  }

  return (
    <Grid container columns="equal">
      <Grid.Row>
        <Grid.Column>
          <Image src={imageUrl} size="medium" circular centered />

          <div className="content">
            <h2
              className="header"
              style={{
                textAlign: "center",
                fontFamily: "'pokemon_gbregular'",
              }}
            >
              {userMetadata?.name}
            </h2>
            <h4
              className="header"
              style={{
                textAlign: "center",
                fontFamily: "'pokemon_gbregular'",
              }}
            >
              PokemonGO Code
            </h4>
            <h2
              className="header"
              style={{
                textAlign: "center",
                fontFamily: "'pokemon_gbregular'",
              }}
            >
              {userMetadata?.pokemongo_id}
            </h2>
          </div>
          <Grid container columns={3}>
            <Grid.Row>
              <Grid.Column></Grid.Column>
              <Grid.Column>
                {/* <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      prodUrl + `/profile/ ${userMetadata?.id}`
                    );
                  }}
                > */}
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `pokemonbinder.net/profile/${userMetadata?.id}`
                    );
                  }}
                >
                  <Popup
                    trigger={
                      <Image centered size="medium" src={shareProfile} />
                    }
                    content="click to copy profile URL"
                    position="top left"
                  />
                </a>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid container>
            <Grid.Column only="computer">
              <TopCards topCardData={userMetadata} />
            </Grid.Column>
          </Grid>
          <Segment>
            <ImageList pokemon={userMetadata.pokemon_cards || []} />
          </Segment>
        </Grid.Column>
      </Grid.Row>
      {/* </div> */}
    </Grid>
  );
};

export default Profile;
