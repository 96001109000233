import React, { useEffect, useState } from "react";
import ProfileBar from "../components/ProfileBar";
import shareProfile from "../images/shareProfile.png";
import ShareLinkButton from "../components/ShareLinkButton";
import ImageList from "../components/ImageList";
import { useAuth0 } from "@auth0/auth0-react";
import getUserData from "../components/utilites/api/api-components/RetrieveUserInfo";
import axios from "axios";
import ProfileBinder from "../components/ProfilePageCards";
import TopCards from "../components/ProfilePageTopCards";
import "../components/ProfilePageTopCards.scss";
import {
  Button,
  Header,
  Image,
  Popup,
  Modal,
  Grid,
  Dimmer,
  Loader,
  Segment,
  Container,
} from "semantic-ui-react";
const prodUrl = "https://poketrack-backend.charo.gg";
const devUrl = "http://127.0.0.1:5000";

const baseURL = prodUrl + "/user/";
const Profile = () => {
  const [userMetadata, setUserMetadata] = useState({});
  const { user, isAuthenticated } = useAuth0();

  const [imageUrl, setImageUrl] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    //check for q param
    // if q param in url check if access token matches q param is my prfoile = true
    //else user profile = false
    // if no q param default to user profile
    //

    const getUserMetadata = async () => {
      try {
        // const token = await getAccessTokenSilently();

        const metadataResponse = await axios.get(baseURL + user.ProfileUserID);

        const user_metadata = await metadataResponse.data;

        setUserMetadata(user_metadata);

        setLoading(false);
        if (user?.ProfileUserID) {
          setImageUrl(
            `https://storage-api.charo.gg/storage/?image_id=${user?.ProfileUserID}&app_id=pokemonbinder`
          );
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [user?.sub]);
  if (isLoading) {
    const mewLoading =
      "https://media3.giphy.com/media/IQebREsGFRXmo/giphy.gif?cid=ecf05e47oj5t8kxu5xid65ntl3ht9fetsl7kiccxbyck3h3x&rid=giphy.gif&ct=s";

    return (
      <div>
        <div style={{ textAlign: "center" }}>
          Loading...
          <Image centered src={mewLoading}></Image>
        </div>
      </div>
    );
  }

  //   let { id } = useParams();
  //   let notmyprofile = id ? true : false;

  return (
    isAuthenticated && (
      <Grid container columns="equal">
        <Grid.Row>
          <Grid.Column>
            <Image src={imageUrl} size="medium" circular centered />

            <div className="content">
              <h2
                className="header"
                style={{
                  textAlign: "center",
                  fontFamily: "'pokemon_gbregular'",
                }}
              >
                {userMetadata?.name}
              </h2>
              <h4
                className="header"
                style={{
                  textAlign: "center",
                  fontFamily: "'pokemon_gbregular'",
                }}
              >
                PokemonGO Code
              </h4>
              <h2
                className="header"
                style={{
                  textAlign: "center",
                  fontFamily: "'pokemon_gbregular'",
                }}
              >
                {userMetadata?.pokemongo_id}
              </h2>
            </div>
            <Grid container columns={3}>
              <Grid.Column></Grid.Column>
              <Grid.Column>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `pokemonbinder.net/profile/${userMetadata?.id}`
                    );
                  }}
                >
                  <Popup
                    trigger={<Image fluid centered src={shareProfile} />}
                    content="click to copy profile URL"
                    position="top left"
                  />
                </a>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid>
            <Grid container>
              <Grid.Column only="computer">
                <TopCards topCardData={userMetadata} />
              </Grid.Column>
            </Grid>
            <Segment>
              <ImageList pokemon={userMetadata.pokemon_cards || []} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        {/* </div> */}
      </Grid>
    )
  );
};

export default Profile;
