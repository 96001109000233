import React from "react";
import { Button } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import "./SearchBar.css";
const LoginButton = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <div>
      {isAuthenticated && (
        <button className="button-navbar1" onClick={() => logout()}>
          Sign Out
        </button>
      )}
      {!isAuthenticated && (
        <button className="button-navbar1" onClick={() => loginWithRedirect()}>
          Login/Sign Up
        </button>
      )}
    </div>
  );
};

export default LoginButton;
