import React, { Component } from "react";
import { Form, Segment } from "semantic-ui-react";
import axios from "axios";
import { withAuth0 } from "@auth0/auth0-react";

import { ToastContainer, toast } from "react-toastify";
const prodUrl = "https://poketrack-backend.charo.gg";
const devUrl = "http://127.0.0.1:5000";

class SettingsForm extends Component {
  state = { name: "", pokemonGoId: "" };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = async () => {
    const { user, getAccessTokenSilently } = await this.props.auth0;

    const token = await getAccessTokenSilently();
    const { name, pokemonGoId } = this.state;

    const { ProfileUserID: usernumID } = await user;

    const res = await axios
      .patch(
        prodUrl + "/user/" + usernumID,
        {
          name: name,
          pokemongo_id: pokemonGoId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          toast.success("Display Name/PokemonGO updated!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        if (res.data.success === false) {
          toast.warn(res.data.username_taken, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  render() {
    const { name, pokemonGoId } = this.state;

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Input
              style={{ backgroundColor: "#d1d1d1" }}
              width={6}
              placeholder="Display Name"
              name="name"
              value={name}
              onChange={this.handleChange}
            />
            <Form.Input
              width={6}
              placeholder="Pokemon GO Code"
              name="pokemonGoId"
              value={pokemonGoId}
              onChange={this.handleChange}
            />
            <Form.Button color="green" success content="Submit" />
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default withAuth0(SettingsForm);
