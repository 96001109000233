import React, { useState } from "react";
import { Button, Header, Image, Modal, Grid, Segment } from "semantic-ui-react";

import "react-toastify/dist/ReactToastify.css";

import AddCardButton from "./utilites/api/api-components/AddCard";
import "./ImageCard.scss";
import AddTopCardButton from "./utilites/api/api-components/AddTopcard1";
import DeleteCardButton from "./utilites/api/api-components/DeleteCard";
import { Nav } from "react-bootstrap";
class ImageCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { spans: 0, open: false, post: {}, deleted: {} };
    this.imageRef = React.createRef();
  }
  componentDidMount() {
    this.imageRef.current.addEventListener("load", this.setSpans);
  }
  setSpans = () => {
    const spans = Math.ceil(200 / 10);

    this.setState({ spans });
  };

  onClickHandler = (post) => {
    this.setState({ open: false });
    this.props.toastPop(post);
  };

  onDeleteHandler = (deleted) => {
    this.setState({ deleted });

    this.setState({ open: false });
    this.props.toastPopDelete(deleted);
  };

  render() {
    const urls = this.props.image;
    const description = this.props.description;
    const name = this.props.name;
    const pokemonid = this.props.pokemonid;
    const number = this.props.pokemon_number;
    const set = this.props.pokemon_set;
    const rarity = this.props.rare;
    const linkSetName = `/card/${pokemonid}`;

    const linkSetStringName = linkSetName.toString().replaceAll(" ", "");
    // console.log(linkSetStringName);
    return (
      <div>
        {/* <Modal
          style={{ background: "transparent" }}
          closeIcon
          onClose={() => this.setState({ open: false })}
          onOpen={() => this.setState({ open: true })}
          open={this.state.open}
          trigger={ */}
        <div>
          <Nav.Link href={linkSetStringName}>
            <a className="card-cards">
              <img
                className="side front"
                ref={this.imageRef}
                alt={description}
                src={urls}
              />
            </a>
          </Nav.Link>
        </div>
        {/* }
        >
          <Modal.Content
            image
            style={{
              backgroundSize: "100% 100%",
              background: "rgba(0, 240, 255, 0.38)",
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(20px)",
              WebkitBackdropFilter: "blur(20px)",
              padding: "60px",
              border: " 1px solid rgba(0, 240, 255, 0.3)",
            }}
          >
            <Image
              wrapped
              className="cards-container center"
              src={urls}
              size="large"
            />

            <Modal.Description>
              <Grid
                container
                style={{
                  backgroundSize: "100% 100%",
                  background: "rgba(255, 255, 255, 0.9)",

                  borderRadius: "16px",
                  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                  backdropFilter: "blur(20px)",
                  WebkitBackdropFilter: "blur(20px)",
                  padding: "60px",
                  border: " 1px solid rgba(255, 255, 255, 0.3)",
                }}
              >
                <Header style={{ fontFamily: "'pokemon_gbregular'" }}>
                  <Segment>
                    {name} #{number}
                  </Segment>
                </Header>

                {description && (
                  <Segment>
                    <p>{description}</p>
                  </Segment>
                )}
                <Segment>
                  <p>Set: {set}</p>
                </Segment>
                <Segment>
                  <p>Rarity: {rarity}</p>
                </Segment>
                <Segment>
                  {" "}
                  <p>
                    Would you like to add <b>{name}</b> to your Binder?
                  </p>
                </Segment>

                <Grid container columns="equal">
                  <Grid.Column>
                    <DeleteCardButton
                      pokemonId={pokemonid}
                      pokemonName={name}
                      onCall={this.onDeleteHandler}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <AddCardButton
                      content="Add Card"
                      onCall={this.onClickHandler}
                      pokemonId={pokemonid}
                      pokemonName={name}
                    />
                  </Grid.Column>
                </Grid>
                <Grid container verticalAlign="top" centered>
                  <Grid.Column>
                    <AddTopCardButton
                      pokemonName={name}
                      pokemonid={pokemonid}
                    ></AddTopCardButton>
                  </Grid.Column>
                </Grid>
              </Grid>
            </Modal.Description>
          </Modal.Content>
        </Modal> */}
      </div>
    );
  }
}
export default ImageCard;
