import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import AccountSettings from "../../views/AccountSettings";
import App from "../App";
import PublicProfile from "../../views/PublicProfile";
import Profile from "../../views/Profile";
import BrowseSets from "../../views/BrowseSets";
import NewNavBar from "../NewNavBar";
import NavBar from "../NavBar";
import SetCards from "../../views/SetCards";
import CardInfo from "../../views/CardInfo";
const Router = () => {
  return (
    <div className="ui container">
      <BrowserRouter>
        <div>
          <NewNavBar />
          {/* <NavBar /> */}
          <Route path="/" exact component={App} />
          <Route path="/profile" exact component={Profile} />
          <Route path="/profile/:id" component={PublicProfile} />
          <Route path="/card/:id" exact component={CardInfo} />
          <Route path="/sets" exact component={BrowseSets} />

          <Route path="/sets/:setname" exact component={SetCards} />
          <Route path="/accountsettings" exact component={AccountSettings} />
        </div>
      </BrowserRouter>
    </div>
  );
};

export default Router;
