import React from "react";
import "./SearchBar.css";
import {
  Button,
  Header,
  Modal,
  Grid,
  Segment,
  List,
  Popup,
  Container,
  Label,
} from "semantic-ui-react";
class SearchBar extends React.Component {
  state = { term: "" };
  onInputChange = (event) => {
    this.setState({ term: event.target.value });
  };
  onFormSubmit = (event) => {
    event.preventDefault();
    this.props.onTheSubmit(this.state.term);
  };
  render() {
    return (
      <div className="" style={{ padding: "10px" }}>
        <form onSubmit={this.onFormSubmit} className="ui form">
          <div className="field">
            <label style={{ color: "white" }}>Pokemon Search</label>
            <input
              type="text"
              value={this.state.term}
              placeholder="Search all cards..."
              onChange={this.onInputChange}
              style={{
                fontFamily: "pokemon_gbregular",
                color: "white",
                background: "rgba(255, 255, 255, 0.23)",
                borderRadius: "16px",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                backdropFilter: "blur(20px)",
                WebkitBackdropFilter: "blur(20px)",
              }}
            />
          </div>
        </form>
      </div>
    );
  }
}
export default SearchBar;

// <div className="" style={{ padding: "10px" }}>
// <Popup
//   hoverable
//   on="hover"
//   trigger={
//     <Button.Group basic size="large">
//       <Button icon="question circle" />
//     </Button.Group>
//   }
//   content="Looking for a specific Pokémon card? Search the site with our search bar"
//   position="right center"
// >
//   <form onSubmit={this.onFormSubmit} className="ui form">
//     <div className="field">
//       <label>Pokemon Search</label>
//       <input
//         type="text"
//         value={this.state.term}
//         placeholder="Search all cards..."
//         onChange={this.onInputChange}
//         style={{
//           fontFamily: "pokemon_gbregular",
//           color: "black",
//           background: "rgba(255, 255, 255, 0.23)",
//           borderRadius: "16px",
//           boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
//           backdropFilter: "blur(20px)",
//           WebkitBackdropFilter: "blur(20px)",
//         }}
//       />
//     </div>
//   </form>
// </Popup>
// </div>
