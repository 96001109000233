import React from "react";
import { GridColumn, Image } from "semantic-ui-react";

import SetCard from "./SetCard";
import myBackgroundImage from "../images/bg.png";
import {
  Button,
  Header,
  Modal,
  Label,
  Grid,
  List,
  Segment,
  Container,
} from "semantic-ui-react";

const SetList = ({ cardSets }) => {
  const collectionSeries = Object.keys(cardSets)
    .reverse()
    .map((pokemonSeriesName) => {
      let sName = pokemonSeriesName;

      let randomKey = new Date().getTime() * Math.random() * 100000;

      // let sNameLogo = sName[0].logo;
      let index = cardSets[sName].length - 1; //accessing last index so correct series logo is displayed
      let sNameLogo = cardSets[sName][index].logo;

      if (sName === "Black & White") {
        sNameLogo = cardSets[sName][index - 1].logo;
      }
      if (sName === "Sword & Shield") {
        sNameLogo = cardSets[sName][index - 1].logo;
      }

      if (sName === "Evolutions") {
        sNameLogo = cardSets[sName][0].logo;
      }
      return (
        <Segment style={{ padding: "2rem 3rem" }}>
          <Grid.Row
            style={{
              padding: "1.5rem 0",
              maxWidth: "1080px",
              // backgroundImage: `url(${myBackgroundImage})`,
              border: "1px solid rgba(255, 255, 255, 0.3)",
              borderRadius: "5px",
              backgroundColor: "rgb(50,50,50)",
            }}
          >
            <Image
              style={{ maxHeight: "200px" }}
              size="medium"
              centered
              src={sNameLogo}
            />
          </Grid.Row>
          <Grid.Row
            style={{
              // backgroundColor: "purple",
              verticalAlign: "center",
            }}
          >
            <SetCard key={randomKey} cardData={cardSets[sName]} />
          </Grid.Row>
        </Segment>
      );
    });
  return <>{collectionSeries || []}</>;
};

export default SetList;
