import React from "react";
import qs from "qs";
import "./SearchBar.css";
import SearchBar from "./SearchBar";
import ImageList from "./ImageList";
import DropDownMenu from "./DropDownMenu";
import Information from "./Infomation";
import FeaturedSet from "./FeaturedSet";
import axios from "axios";
import {
  Button,
  Header,
  Image,
  Modal,
  Grid,
  Segment,
  Popup,
  Loader,
} from "semantic-ui-react";

const prodUrl = "https://poketrack-backend.charo.gg";
const devUrl = "http://127.0.0.1:5000";
const baseURL = prodUrl + "/binder/";

class App extends React.Component {
  state = { pokemon: [], optionSelected: [], term: "", isLoading: false };

  onSearchSubmit = async (term) => {
    this.setState({ isLoading: true });
    const response = await axios.get(baseURL, {
      params: { name: term, set: this.state.optionSelected },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });

    this.setState({ pokemon: response.data.pokemon });
    this.setState({ isLoading: false });
  };
  onFilterChange = (optionSelected) => {
    this.setState({ optionSelected });
  };

  render() {
    const mewLoading =
      "https://media3.giphy.com/media/IQebREsGFRXmo/giphy.gif?cid=ecf05e47oj5t8kxu5xid65ntl3ht9fetsl7kiccxbyck3h3x&rid=giphy.gif&ct=s";
    return (
      <>
        {" "}
        <div
          className="ui container"
          style={{
            fontFamily: "pokemon_gbregular",

            padding: "1rem 2rem",
            // backgroundColor: "rgb(50,50,50)",
            // borderRadius: "16px",
            // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(20px)",
            WebkitBackdropFilter: "blur(20px)",
          }}
        >
          <Grid container>
            <Grid.Row>
              <Grid.Column width={15}>
                <SearchBar
                  onFilterChange={this.onFilterChange}
                  onTheSubmit={this.onSearchSubmit}
                />
                {this.state.isLoading && (
                  <div style={{ textAlign: "center" }}>
                    Loading...
                    <Image centered src={mewLoading}></Image>
                  </div>
                )}
                {/* <DropDownMenu onFilterChange={this.onFilterChange} /> */}
                <ImageList pokemon={this.state.pokemon} />
              </Grid.Column>
              <Grid.Column width={1} textAlign="center">
                {" "}
                <Popup
                  hoverable
                  on="hover"
                  trigger={
                    <Button
                      style={{
                        position: "absolute",
                        float: "right",
                        transform: "translate(100%,100%)",
                      }}
                      color="black"
                      icon="question circle"
                    />
                  }
                  content="Looking for a specific Pokémon card? Search the site with our search bar"
                  position="right center"
                ></Popup>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {/* <Information /> */}
          <FeaturedSet />
        </div>
      </>
    );
  }
}

export default App;
